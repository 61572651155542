import React from 'react'
import { connect } from 'react-redux'
import { Typography, Grid } from '@mui/material'
import { styled } from '@mui/material/styles'
import { db } from '../system/firebase/index'
import { doc, deleteDoc, setDoc, getDoc, serverTimestamp } from 'firebase/firestore'
import { setIsEdit } from '../system/redux/reducers/globals'
import { closeModal } from '../system/redux/reducers/modal'
import { StaticImage } from "gatsby-plugin-image"
import { getAuth } from 'firebase/auth'

const ModalBackground = styled(Grid)(({ theme }) => ({
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    color: theme.palette.white.main,
    height: '100vh',
    justifyContent: 'center',
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: '100vw',
    zIndex: 1000,
}))

const ConfirmModal = ({ modal, dispatch, data }) => {
    const close = (e) => {
        dispatch(closeModal())
    }
    const deleteListing = async () => {
        const auth = getAuth() // Get the current authenticated user
        const user = auth.currentUser

        if (!user) {
            console.error("No authenticated user found")
            return
        }

        const listingRef = doc(db, 'listings', data.docId)
        const deletedRef = doc(db, 'deleted_listings', data.docId)

        try {
            // Fetch listing data before deleting
            const listingSnap = await getDoc(listingRef)

            if (listingSnap.exists()) {
                const listingData = listingSnap.data()

                // Save deleted data including the user info
                await setDoc(deletedRef, {
                    ...listingData,
                    deleted_at: serverTimestamp(),
                    deleted_by: {
                        uid: user.uid,
                        email: user.email
                    }
                })

                // Delete the original listing
                await deleteDoc(listingRef)
            }
            dispatch(setIsEdit(false))
            dispatch(closeModal())
        } catch (error) {
            console.error("Error deleting listing:", error)
        }
    }

    if (!modal.isOpen) {
        return null
    } else {
        return (
            <ModalBackground container={true} onClick={close}>
                <Grid alignItems='center' bgcolor='black.main' container={true} flexDirection='column' justifyContent='center' py={3} width={['auto', 'auto', 'auto', 'auto', '550px']} onClick={(e) => e.stopPropagation()}>
                    <Typography mx={3}>Are you sure you want to delete this item?</Typography>
                    <Grid container={true} flexWrap='nowrap' mt={6} justifyContent='space-evenly'>
                        <Grid
                            alignItems='center'
                            justifyContent='center'
                            container={true}
                            fontSize='16px'
                            fontFamily='IBM Plex sans'
                            item
                            onClick={deleteListing}
                            xs={2}
                            sx={{ cursor: 'pointer' }}>
                            <StaticImage src="../assets/svg/icn_check.svg" alt="" />
                            <Typography variant='ibm' ml={2}>Confirm</Typography>
                        </Grid>
                        <Grid
                            alignItems='center'
                            justifyContent='center'
                            container={true}
                            fontSize='16px'
                            fontFamily='IBM Plex sans'
                            item
                            onClick={close}
                            xs={2}
                            sx={{ cursor: 'pointer' }}>
                            <StaticImage src="../assets/svg/icn_close.svg" alt="" />
                            <Typography variant='ibm' ml={2}>Cancel</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </ModalBackground>
        );
    }
}

function mapStateToProps(state) {
    return {
        modal: state.Modal,
    };
}

export default connect(mapStateToProps)(ConfirmModal)
